export enum LocationGroupType {
  MATCHES = 'matches',
  POPULAR_DESTINATIONS = 'popular_destinations',
  RECENT_SEARCHES = 'recent_searches',
  OTHER = 'other',
  HOMEPAGE = 'homepage',
}

const SURFACED_LOCATION_KEY = 'surfacedLocation';

export const getSurfacedLocation = (): LocationGroupType => {
  const defaultValue = LocationGroupType.OTHER;

  try {
    const value = window.sessionStorage.getItem(SURFACED_LOCATION_KEY) as LocationGroupType;
    if (value) {
      return value;
    } else {
      window.sessionStorage.setItem(SURFACED_LOCATION_KEY, defaultValue);
      return defaultValue;
    }
  } catch {
    return defaultValue;
  }
};

export const setSurfacedLocation = (groupName: LocationGroupType) => {
  try {
    window.sessionStorage.setItem(SURFACED_LOCATION_KEY, groupName);
  } catch {}
};

export const mapLocationLabelToGroupName = (label = ''): LocationGroupType => {
  switch (label) {
    case 'Matches':
      return LocationGroupType.MATCHES;
    case 'Recent Searches':
      return LocationGroupType.RECENT_SEARCHES;
    case 'Popular Destinations':
      return LocationGroupType.POPULAR_DESTINATIONS;
    default:
      return LocationGroupType.OTHER;
  }
};
