import { AnalyticsBrowser } from '@segment/analytics-next';
import { v4 as uuidv4 } from 'uuid';

import { parsePageMetaData, TPageViewMetaData } from '@/services/analytics/pageEventUtils';
import { EProjectSection } from '@/services/analytics/trackEventV2';
import { getCookie, removeCookie, setCookie } from '@/utility/cookie';
import { logger } from '@/utility/logger';

// memoized get/init
let analytics: AnalyticsBrowser | null = null;
export const getSegment = (enabled?: boolean, segmentKey?: string) => {
  if (analytics) return analytics;
  if (!enabled || !segmentKey) {
    return null;
  }
  analytics = AnalyticsBrowser.load({ writeKey: segmentKey });
  analytics.addSourceMiddleware(({ payload, next }) => {
    // For experiment viewed, we will use a custom messageId which is only possible with source middleware.
    // This message ID acts as a cache key that allows segment to drop duplicate experiment viewed events.
    if (!payload?.obj?.properties) return next(payload);
    const eventName = payload.obj.event;
    if (eventName !== 'Experiment Viewed') return next(payload);
    const messageId = payload.obj.properties.messageId;
    delete payload.obj.properties.messageId;
    payload.obj.messageId = messageId;
    next(payload);
  });
  return analytics;
};

const SEGMENT_ANONYMOUS_ID_COOKIE = 'odc-seg-did';
const OD_ANONYMOUS_ID_COOKIE = 'odc-aid';

export const trackPageView = async ({
  window,
  pageType,
  projectSection,
  storedPageMetaData,
  extraMetaData,
}: {
  pageType?: string;
  storedPageMetaData?: Storage;
  projectSection: EProjectSection;
  extraMetaData?: Record<string, string>;
  window: Window;
}): Promise<void> => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const universalFields = {
    platform: 'web',
    pageUrl: window.location.pathname,
    property: projectSection,
    legacyDeviceID: getCookie('odc-d'),
    cam: urlParams.get('cam'),
    subcam: urlParams.get('subcam'),
  };

  let pageViewMetaData: TPageViewMetaData | null = null;
  if (storedPageMetaData) {
    pageViewMetaData = parsePageMetaData(storedPageMetaData, pageType);
  }

  await analytics?.page(`${window.location.pathname}`, window.location.toString(), {
    ...(pageViewMetaData || {}),
    ...(extraMetaData || {}),
    ...universalFields,
  });
};

type TUserLogin = {
  email: string;
  userId: number;
  actorId: number | null;
  deviceId: string | null;
  anonymousId: string | null;
  isOwner: boolean;
  isGuest: boolean;
  createdAt: string | null;
  firstName: string | null;
  lastName: string | null;
  referralCode: string | null;
};

export const trackUserLoginOrUpdate = async (userLogin: TUserLogin): Promise<void> => {
  const userId = String(userLogin.userId);
  analytics?.identify(
    userId,
    {
      ...userLogin,
      actorId: userLogin.actorId || 'non-actor',
    },
    {
      anonymousId: userLogin.anonymousId,
      integrations: {
        All: true,
      },
    },
  );
};

export const getODAnonymousId = async () => {
  let anonymousId: string | undefined | null;
  // Check Segment cookie for users that visited the site and have a Segment anonymous id before we switched to our
  // own anonymous id
  anonymousId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE);

  // Try to get it from OD_ANONYMOUS_ID_COOKIE
  if (!anonymousId) {
    anonymousId = getCookie(OD_ANONYMOUS_ID_COOKIE);
  }

  // Try to get it from OD_ANONYMOUS_ID_COOKIE from localStorage
  if (!anonymousId && window.localStorage) {
    anonymousId = window.localStorage.getItem(OD_ANONYMOUS_ID_COOKIE);
  }

  // If we still don't have it generate it using uuidv4()
  if (!anonymousId) {
    anonymousId = uuidv4();
    // Save to cookie & localStorage
    setCookie(OD_ANONYMOUS_ID_COOKIE, anonymousId);
    if (window.localStorage) {
      window.localStorage.setItem(OD_ANONYMOUS_ID_COOKIE, anonymousId);
    }
  }

  await analytics?.setAnonymousId(anonymousId);

  logger.log('[OD] anonymousId is', anonymousId);

  return anonymousId;
};

/**
 * Reset the segment user and return the new anonymousId
 * Seems to be a bug with segment not persisting the new anonId,
 * so we will just get and set the anonId as a workaround.
 */
export const resetSegmentUser = async (): Promise<string | undefined> => {
  await analytics?.reset();
  removeCookie(SEGMENT_ANONYMOUS_ID_COOKIE);

  const anonId = await getODAnonymousId();
  if (!anonId) return;
  await analytics?.setAnonymousId(anonId);
  // reset segment logged in user ID as analytics.reset does not clear this data
  const user = await analytics?.user();
  user?.id('');

  return anonId;
};
