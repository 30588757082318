export type ImageSize =
  | 'square20'
  | 'square40'
  | 'square80'
  | 'square96'
  | 'square128'
  | 'square140'
  | 'square160'
  | 'square192'
  | 'square256'
  | 'square320'
  | 'square480'
  | 'square720'
  | 'square1440'
  | 'landscape280'
  | 'landscape375'
  | 'landscape572'
  | 'landscape768'
  | 'landscape1080'
  | 'landscape1440'
  | 'landscape2160'
  | 'landscape2880'
  | 'portrait192'
  | 'portrait375'
  | 'portrait768'
  | 'wide1200'
  | 'default';

export type TImageCropMode = 'auto' | 'crop' | 'fill' | 'fit';

type ImageFormat = {
  width: number;
  height?: number;
};

const imageFormats: { [key in ImageSize]: ImageFormat } = {
  square20: { width: 20, height: 20 },
  square40: { width: 40, height: 40 },
  square80: { width: 80, height: 80 },
  square96: { width: 96, height: 96 },
  square128: { width: 128, height: 128 },
  square140: { width: 140, height: 140 },
  square160: { width: 160, height: 160 },
  square192: { width: 192, height: 192 },
  square256: { width: 256, height: 256 },
  square320: { width: 320, height: 320 },
  square480: { width: 480, height: 480 },
  square720: { width: 720, height: 720 },
  square1440: { width: 1440, height: 1440 },
  landscape280: { width: 280, height: 170 },
  landscape375: { width: 375, height: 280 },
  landscape572: { width: 572, height: 429 },
  landscape768: { width: 768, height: 576 },
  landscape1080: { width: 1080, height: 810 },
  landscape1440: { width: 1440, height: 1080 },
  landscape2160: { width: 2160, height: 1620 },
  landscape2880: { width: 2880, height: 2160 },
  portrait192: { width: 192, height: 192 },
  portrait375: { width: 375, height: 500 },
  portrait768: { width: 768, height: 1024 },
  wide1200: { width: 1200, height: 630 },
  default: { width: 0 },
};

type FormatterFunction = (
  url: string,
  width?: number,
  height?: number,
  cropMode?: TImageCropMode,
) => string;

export const formatCloudinaryURL: FormatterFunction = (url, width, height, cropMode) => {
  const base = ['t_odw', 'a_exif', 'q_auto', 'f_webp'];

  if (height) {
    base.push(`h_${height}`);
  }

  if (width) {
    base.push(`w_${width}`);
  } else {
    base.push(`w_auto`);
  }

  if (cropMode) {
    const mapCropMode: Record<TImageCropMode, string> = {
      auto: 'c_crop',
      crop: 'c_crop',
      fit: 'c_fit',
      fill: 'c_fill',
    };

    base.push(mapCropMode[cropMode]);
  }

  return url.replace(
    '/outdoorsy/image/upload',
    `/outdoorsy/image/upload/c_limit,w_2880,h_2160/${base.join(',')}`,
  );
};

export const formatProxyURL: FormatterFunction = (url, width, height, cropMode) => {
  const base = [];

  if (height) {
    base.push(`height:${height}`);
  }

  if (width) {
    base.push(`width:${width}`);
  }

  if (!(width && height) && !cropMode) {
    base.push('rt:fit');
  }

  if (cropMode) {
    const mapCropMode: Record<TImageCropMode, string> = {
      auto: 'rt:auto',
      crop: 'rt:auto',
      fit: 'rt:fit',
      fill: 'rt:fill',
    };

    base.push(mapCropMode[cropMode]);
  }

  return url.replace('/insecure', `/insecure/${base.join('/')}`);
};

export const getImageFormatter = (url: string) => {
  const formatters: Array<{ test: RegExp; formatter: FormatterFunction }> = [
    {
      test: /res\.cloudinary\.com/,
      formatter: formatCloudinaryURL,
    },
    {
      test: /\.com\/insecure/,
      formatter: formatProxyURL,
    },
  ];

  return formatters.find(it => it.test.test(url))?.formatter;
};

const formatImageURL = (url: string, size: ImageSize, cropMode?: TImageCropMode) => {
  const formatter = getImageFormatter(url);
  const { width, height } = imageFormats[size];
  const formattedURL = formatter?.(url, width, height, cropMode);
  return formattedURL || url;
};

export const getSourceSet = (src: string, sourceSizes: ImageSize[], cropMode?: TImageCropMode) =>
  sourceSizes
    .map(size => {
      const width = imageFormats[size].width ? ` ${imageFormats[size].width}w` : '';
      return `${formatImageURL(src, size, cropMode)}${width}`;
    })
    .join(', ');

export default formatImageURL;
