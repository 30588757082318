import { Button, EButtonColorVariant } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React, { useRef } from 'react';

import { useHostingRVFlow } from '@/components/route/become-a-host/RV/useHostingRVFlow';
import { useHostingStayFlow } from '@/components/route/become-a-host/Stay/useHostingStayFlow';
import { useHostingType } from '@/components/route/become-a-host/useHostingType';
import { CARET_LARGE } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import { useBreakpoint } from '@/hooks/useBreakpoint';

import GlobalHeaderNavSubmenu from '../GlobalHeaderNavSubmenu/GlobalHeaderNavSubmenu';
import css from './GlobalHeaderNavMenuItem.module.css';

interface IGlobalHeaderNavMenuItemProps {
  onTransparentHeader?: boolean;
  secondaryButtonVariant?: boolean;
  asDesktopButton?: boolean;
  'data-testid'?: string;
  href?: string;
  label: string;
  content?: React.ReactNode;
  onClick?: () => void;
  onCloseSubmenu?: () => void;
  submenu?: React.ReactNode;
  submenuOpen?: boolean;
  newNavMenuItem?: boolean;
  newBecomeAHostPage?: boolean;
  linkButton?: boolean;
  rafLink?: boolean;
  isHeaderWhite?: boolean;
}

const GlobalHeaderNavMenuItem: React.FC<IGlobalHeaderNavMenuItemProps> = ({
  ['data-testid']: testid,
  href,
  label,
  content,
  onTransparentHeader,
  secondaryButtonVariant,
  asDesktopButton,
  submenu,
  onClick,
  onCloseSubmenu,
  submenuOpen,
  newNavMenuItem,
  newBecomeAHostPage,
  linkButton,
  rafLink,
  isHeaderWhite,
}) => {
  const containerRef = useRef<HTMLLIElement>(null);
  const linkClassName = cn('flex items-end', {
    highlight: !newNavMenuItem,
    'lg:highlight': newNavMenuItem,
    'text-gray-800': !onTransparentHeader,
  });

  const { isRentalRV } = useHostingType();
  const { redirectToHostingRVFlow, loading } = useHostingRVFlow();
  const { redirectToHostingStayFlow } = useHostingStayFlow();
  const handleDismissSubmenu = () => {
    onCloseSubmenu?.();
  };

  const { isAboveDesktop } = useBreakpoint();
  const redirectToHostingFlow = isRentalRV ? redirectToHostingRVFlow : redirectToHostingStayFlow;

  return (
    <li
      role="menuitem"
      aria-haspopup={!href}
      className={cn('py-5 autoType900 md:autoType800 xl:text xl:autoType300 xl:ml-8 xl:p-0', {
        [css.newNavMenuItem as string]: newNavMenuItem,
        'text-gray-900': !onTransparentHeader,
      })}
      ref={containerRef}>
      {(() => {
        if (newBecomeAHostPage && !linkButton) {
          return (
            <Button
              fullWidth={!isAboveDesktop}
              onClick={redirectToHostingFlow}
              variant={
                !isAboveDesktop || secondaryButtonVariant
                  ? EButtonColorVariant.Secondary
                  : EButtonColorVariant.Primary
              }
              label={label}
              loading={loading}
            />
          );
        }

        if (href && !linkButton) {
          if (asDesktopButton) {
            return (
              <Button
                href={href}
                fullWidth={!isAboveDesktop}
                onClick={onClick}
                label={label}
                onDark={onTransparentHeader}
                variant={
                  !onTransparentHeader && !isHeaderWhite && !secondaryButtonVariant
                    ? EButtonColorVariant.Primary
                    : EButtonColorVariant.Secondary
                }
                className={cn({
                  '!bg-canvas-200 !border-canvas-200 !text-black hover:opacity-75':
                    rafLink && !onTransparentHeader && !secondaryButtonVariant,
                })}
              />
            );
          }

          return (
            <a
              href={href}
              className={`${linkClassName} ${css.link} before-focus-style`}
              onClick={onClick}
              data-on-transparent-header={String(onTransparentHeader)}
              data-desktop-button={String(asDesktopButton)}
              data-testid={testid}>
              {label}
              <Icon
                name={CARET_LARGE}
                className={`w-4 mb-1 ml-2 transform rotate-180 ${
                  newNavMenuItem ? 'hidden' : 'xl:hidden'
                }`}
              />
            </a>
          );
        }

        return (
          <button
            className={`${linkClassName} ${css.link} before-focus-style`}
            onClick={newBecomeAHostPage ? redirectToHostingFlow : onClick}
            data-testid={testid}
            data-on-transparent-header={String(onTransparentHeader)}>
            {content ?? (
              <>
                {label}
                <Icon
                  name={CARET_LARGE}
                  className={`w-4 mb-1 ml-2 transform rotate-180 xl:-rotate-90 xl:m-0 xl:w-3 xl:translate-x-1 xl:translate-y-0.5 ${
                    newNavMenuItem
                      ? 'lg:-rotate-90 lg:m-0 lg:w-3 lg:translate-x-1 lg:translate-y-0.5'
                      : ''
                  } ${css.linkCaret}`}
                  data-has-submenu={String(Boolean(submenu))}
                />
              </>
            )}
          </button>
        );
      })()}
      {submenu && (
        <GlobalHeaderNavSubmenu
          anchorRef={containerRef}
          open={submenuOpen}
          onDismiss={handleDismissSubmenu}>
          {submenu}
        </GlobalHeaderNavSubmenu>
      )}
    </li>
  );
};

export default GlobalHeaderNavMenuItem;
