export enum OptimizelyFlags {
  LISTING_ORDER = 'listing_module_order_web',
  CAMPSITE_LISTING_ORDER = 'campsite_listing_module_order_web',
  SEARCH_SORT_LOGIC = 'search_sort_logic_web',
  TRIP_DETAILS_ORDER = 'trip_details_module_order_web',
  HOMEPAGE_CUSTOM_BANNER_WEB = 'homepage_custom_banner_web',
  FREE_CANCELLATION_PERIOD = 'free_cancellation_period',
  SYSTEM_DOWNTIME = 'system_downtime',
  TRIP_DETAILS_BANNER = 'trip_details_banner',
  BLOG_BANNER = 'blog_banner',
  LISTINGS_NEARBY_THRESHOLD_WEB = 'listings_nearby_threshold_web',
  CAMPGROUNDS_SEARCH_TAB_AND_HOME_TAB = 'campgrounds_web',
  USM_FOR_GUIDE_PAGES = 'usm_for_guide_pages',
  USM_INSTANT_BOOK = 'usm_instant_book',
  MAP_LAYERS = 'map_layers',
  STAY_CAMPGROUND_FEATURE_FILTER = 'stay_campground_feature_filter',
  ROAMLY_WEATHER = 'roamly_weather',
  CAMPGROUND_DATE_CHANGE = 'campground_date_change_ui',
  RV_STAYS_DELIVERY_WEB = 'rv_stays_delivery_web',
  CHECK_DRIVABLE_DISTANCE = 'check_drivable_distance',
  CAPTAIN_BLOG_AI_ENABLED = 'captain_blog_ai_enabled',
  ROAMLY_WEATHER_PRE_PURCHASE = 'roamly_weather_pre_purchase',
  SINGLE_TILE_GRID = 'single_tile_grid',
  CAMPGROUND_DISCOUNTING_BANNER = 'campgrounds_discounting_banner',
  OUTDOORSY_STAYS = 'outdoorsy_stays',
  ATTENTIVE_ENABLED = 'attentive_enabled',
  SERP_GUIDED_SEARCH = 'serp_guided_search',
  GTM_ENABLED = 'gtm_enabled',
  SHOW_PRO_BADGE = 'show_pro_badge',
}

export enum EAlgoSlugVariationKey {
  weekender = 'weekend_availability',
}

export enum ESerpGuidedSearchVariationKey {
  RV_WIZARD_WHERE_TO_START = 'rv_wizard_where_to_start',
  RV_WIZARD_WHICH_RV = 'rv_wizard_which_rv',
  MY_RV_FIT_WHERE_TO_START = 'my_rv_fit_where_to_start',
  RV_MATCHMAKER_WHICH_RV = 'rv_matchmaker_which_rv',
  RV_NAVIGATOR_GUESSWORK = 'rv_navigator_guesswork',
  RV_NAVIGATOR_GET_STARTED = 'rv_navigator_get_started',
  RV_SCOUT_GET_STARTED = 'rv_scout_get_started',
}
