import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';

import Favorite from '@/assets/icons/favorite.svg';
import Home from '@/assets/icons/home.svg';
import Map from '@/assets/icons/map.svg';
import Message from '@/assets/icons/message-circle.svg';
import Search from '@/assets/icons/search.svg';
import Link, { LinkVariants } from '@/components/switchback/Link/Link';
import { useGetSoonestTripIdQuery } from '@/graphql/types-and-hooks';
import { useFixedFooterVisibility } from '@/hooks/useFixedFooterVisibility';
import { useRouter } from '@/hooks/useRouter';
import { getIsAuthenticated, getUnreadConversations } from '@/redux/selectors/auth/user';

interface IFixedFooterProps {
  isHomePage?: boolean;
  stickyElements?: React.ReactNode;
}

export const FixedFooter: React.FC<IFixedFooterProps> = ({ isHomePage, stickyElements }) => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const unreadMessages = useSelector(getUnreadConversations);
  const router = useRouter();
  const currentPath = router.pathname;
  const { data: soonestTrip } = useGetSoonestTripIdQuery({ skip: !isAuthenticated });
  const soonestTripId = soonestTrip?.getSoonestTripId?.booking_id;
  const footerItems = [
    {
      icon: <Home height={24} />,
      label: <FormattedMessage defaultMessage="Home" id="ejEGdx" />,
      link: '/',
    },

    {
      icon: <Search height={24} />,
      label: <FormattedMessage defaultMessage="Search" id="xmcVZ0" />,
      link: '/rv-search',
    },

    {
      icon: <Favorite height={24} />,
      label: <FormattedMessage defaultMessage="Favorites" id="SMrXWc" />,
      link: '/saved',
    },

    {
      icon: <Map height={24} />,
      label: <FormattedMessage defaultMessage="Trips" id="XmO9g/" />,
      link: soonestTripId ? `/trips/${soonestTripId}` : '/trips',
    },

    {
      icon: <Message height={24} />,
      label: <FormattedMessage defaultMessage="Messages" id="hMzcSq" />,
      link: '/messages',
    },
  ];

  const showFooter = useFixedFooterVisibility();

  useEffect(() => {
    const helpButton = document.querySelector('.help-button');
    if (helpButton) {
      helpButton.classList.add(showFooter ? '!mb-[60px]' : '!mb-0');
      helpButton.classList.remove(showFooter ? '!mb-0' : '!mb-[60px]');
    }
  }, [showFooter]);

  return (
    <footer
      className={`fixed bottom-0 left-0 z-10 block shadow-200 w-full lg:hidden transition-transform duration-300 ${showFooter ? '' : 'translate-y-full'}`}
      // This is being used by Help menu in GTM to position the button properly
      data-sticky="true">
      {stickyElements && <div className="absolute w-full -translate-y-full">{stickyElements}</div>}

      <ul
        style={isHomePage ? { backgroundColor: 'var(--page-footer-bg-color)' } : {}}
        className={`flex mx-auto max-w-xxl ${isHomePage ? 'transition-[background-color] duration-700 ease-in-out' : 'bg-canvas-100'}`}>
        {footerItems.map((item, idx) => {
          const isMessages = item.link === '/messages';
          const hasNotifications = isMessages && !!unreadMessages;
          const isTripsPath = item.link.includes('/trips');
          const highlightedPath = isTripsPath ? '/trips' : item.link;
          const highlighted =
            item.link === '/'
              ? item.link === currentPath || isHomePage
              : currentPath.includes(highlightedPath);
          return (
            <li key={`${item.label}-${idx}`} className="flex-1">
              <Link
                href={item.link}
                variant={LinkVariants.noUnderline}
                // We don't want to force clientside routing for the homepage
                // so it will also work with the new homepage experiment.
                // Let cf worker to do the redirect and this should work with both.
                forceClientsideRouting={item.link !== '/'}
                className="flex justify-center">
                <span
                  className={`flex flex-col items-center px-1 xs:px-2 sm:px-3 py-2 border-t-4 relative ${
                    highlighted ? 'border-t-primary-500 text-primary-500' : 'border-t-transparent'
                  } hover:border-t-primary-500`}>
                  {item.icon}
                  {hasNotifications && (
                    <div className="absolute w-2 h-2 ml-1 bg-green-500 rounded right-[0.5rem] sm:right-4" />
                  )}
                  <span className="text-xs text-gray-800 text">{item.label}</span>
                  <span className="sr-only">{item.label}</span>
                </span>
              </Link>
            </li>
          );
        })}
      </ul>
    </footer>
  );
};
