import { Text } from '@outdoorsyco/bonfire';
import { IntlShape } from 'react-intl';

import Anchor from '../switchback/Anchor/Anchor';

type TOneTrustTextProps = {
  intl: IntlShape;
};

export const OneTrustText = ({ intl }: TOneTrustTextProps) => (
  <div className="flex flex-col md:flex-row !mb-5 md:!mb-0">
    <Text className="!text-white !m-0 !text-[10px] md:!text-200">
      {intl.formatMessage(
        {
          defaultMessage:
            'We use cookies and other tracking technology to power your adventure. We do not sell your information. We share information about your use of our site with our social media, advertising and analytics partners. See our <CookiePolicy>cookie policy</CookiePolicy> for more information.',
          id: 'htVXrN',
        },
        {
          CookiePolicy: (text: React.ReactNode) => (
            <Anchor href="/cookie-policy" className="!m-0">
              {text}
            </Anchor>
          ),
        },
      )}
    </Text>
  </div>
);
